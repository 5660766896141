<template>
  <div @submit.prevent @submit="login(loginForm)" class="login-form">
    <v-form class="login-form__form" v-model="valid">
      <h1 class="form__title">{{ $t('auth.login.title') }}</h1>

      <v-text-field
        class="form__field-email"
        v-model="loginForm.email"
        color="primaryFont"
        persistent-placeholder
        hide-details="auto"
        required
        autocomplete
        :rules="emailRules"
        :label="$t('auth.login.email')"
        :placeholder="$t('auth.login.emailPlaceholder')"
      />

      <v-text-field
        class="form__field-password"
        v-model="loginForm.password"
        color="primaryFont"
        persistent-placeholder
        hide-details="auto"
        type="password"
        required
        autocomplete
        :rules="passwordRules"
        :label="$t('auth.login.password')"
        :placeholder="$t('auth.login.passwordPlaceholder')"
      />

      <div class="form__actions">
        <v-btn
          class="actions__submit"
          color="primaryFont"
          type="submit"
          depressed
          block
          :disabled="!valid || isLoading"
          :loading="isLoading"
        >
          {{ $t('auth.login.connect') }}
        </v-btn>

        <v-btn
          class="actions__reset"
          color="primaryFont"
          text
          to="/reset-password"
          data-test-id="reset-btn"
        >
          {{ $t('auth.login.forgot') }}
        </v-btn>

        <v-btn
          class="actions__create"
          color="primaryFont"
          depressed
          :to="registerPath"
          min-width="190"
          data-test-id="create-btn"
        >
          {{ $t('auth.login.create') }}
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import VALIDATORS from '@/helpers/forms/validators.helper';

export default {
  name: 'LoginForm',
  props: ['isLoading'],
  data: () => ({
    loginForm: {
      email: '',
      password: '',
    },
    emailRules: [VALIDATORS.EMAIL.REQUIRED, VALIDATORS.EMAIL.FORMAT],
    passwordRules: VALIDATORS.PASSWORD,
    valid: false,
  }),
  computed: {
    registerPath() {
      const path = '/register';
      const redirect = this.$router.currentRoute?.query?.redirect;

      if (redirect) {
        return `${path}?redirect=${redirect}`;
      }

      return path;
    },
  },
  methods: {
    login(loginForm) {
      this.$emit('login', loginForm);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/core/variables';

.login-form {
  &__form {
    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: rem(20px) 0 0;
  }
}

.form {
  &__title {
    font-size: $xx-large-font-size;
    margin-bottom: rem(30px);
  }

  &__field-email {
    margin-bottom: rem(30px);
  }

  &__field-password {
    margin-bottom: rem(20px);
  }

  &__actions {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.actions {
  &__submit {
    margin-bottom: rem(5px);
  }

  &__reset {
    margin-bottom: rem(20px);
    text-decoration: underline;
    font-weight: $bold;
  }

  &__submit,
  &__create {
    color: white;
    font-weight: $bold;
  }
}

@include breakpoint(medium) {
  .form {
    &__title {
      margin-bottom: rem(40px);
    }

    &__field-email {
      margin-bottom: rem(40px);
    }

    &__field-password {
      margin-bottom: rem(20px);
    }
  }

  .actions {
    &__submit {
      margin-bottom: rem(10px);
    }
  }
}
</style>
