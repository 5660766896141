<template>
  <div class="exception">
    <app-exception :code="code" />
  </div>
</template>

<script>
import AppException from '@/components/app/app-exception/AppException.vue';

export default {
  components: { AppException },
  name: 'Exception',
  props: {
    code: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.exception {
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
