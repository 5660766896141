<template>
  <div id="login">
    <div class="form">
      <register-form class="form" :error="error" :isSending="isSending" @register-user="register" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { LOGIN, REGISTER, SHARED_AUTH_MODULE } from '@/stores/shared/actions/auth/auth.actions';
import RegisterForm from '@/components/register-form/RegisterForm.vue';

export default {
  name: 'Register',
  components: {
    RegisterForm,
  },
  computed: {
    ...mapGetters(SHARED_AUTH_MODULE, ['error', 'isSending']),
  },
  methods: {
    ...mapActions(SHARED_AUTH_MODULE, [REGISTER, LOGIN]),
    async register(registerForm) {
      const loginData = await this[REGISTER](registerForm);
      if (loginData) {
        await this[LOGIN](loginData);
        const redirect = this.$router.currentRoute?.query?.redirect || '/dashboard';
        await this.$router.push(redirect).catch((failure) => {
          console.debug(failure);
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/core/variables';

#login {
  height: calc(100% - 5rem);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@include breakpoint(small) {
  .form {
    width: 30rem;
  }
}
</style>
