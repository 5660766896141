<template>
  <div @submit.prevent @submit="sendForm">
    <v-form v-model="valid" class="form">
      <h1>{{ $t('auth.register.title') }}</h1>

      <div class="form-row">
        <v-text-field
          class="form-row__item-left required"
          v-model="registerForm.firstName"
          :label="$t('auth.register.firstName')"
          :rules="firstnameRules"
          required
        />
        <v-text-field
          class="form-row__item-right required"
          v-model="registerForm.lastName"
          :label="$t('auth.register.lastName')"
          :rules="lastnameRules"
          required
        />
      </div>
      <v-text-field
        class="required"
        v-model="registerForm.email"
        :label="$t('auth.register.email')"
        :rules="emailRules"
        required
      />
      <v-text-field
        class="required"
        v-model="registerForm.password"
        :label="$t('auth.register.password')"
        :rules="passwordRules"
        required
        type="password"
      />

      <span class="legend">{{ $t('auth.register.requiredLegend') }}</span>

      <div class="form-row">
        <v-checkbox
          class="form-row__item-left"
          v-model="registerForm.agreesConditions"
          :rules="conditionsRules"
          name="agreesConditions"
        >
          <slot slot="label" class="d-flex flex-column align-justify">
            <span @click.stop v-html="$t('auth.register.conditions')" />
          </slot>
        </v-checkbox>
      </div>

      <div class="form-row" v-if="false">
        <v-checkbox
          class="form-row__item-left"
          v-model="registerForm.newsletter"
          name="newsletter"
          :label="$t('auth.register.newsletter')"
        >
        </v-checkbox>
      </div>

      <div class="form-row justify-center mt-4">
        <v-btn
          class="mr-2"
          outlined
          color="primary"
          type="submit"
          :disabled="!valid || isSending"
          :loading="isSending"
        >
          {{ $t('auth.register.create') }}
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import VALIDATORS from '@/helpers/forms/validators.helper';

export default {
  name: 'RegisterForm',
  props: ['isSending'],
  data: () => ({
    registerForm: {
      newsletter: false,
      firstName: '',
      lastName: '',
      password: '',
    },
    firstnameRules: VALIDATORS.REQUIRED.FIRSTNAME,
    lastnameRules: VALIDATORS.REQUIRED.LASTNAME,
    emailRules: [VALIDATORS.EMAIL.REQUIRED, VALIDATORS.EMAIL.FORMAT],
    passwordRules: VALIDATORS.PASSWORD,
    conditionsRules: VALIDATORS.REQUIRED.CONDITIONS,
    valid: false,
    menu: false,
  }),
  methods: {
    sendForm() {
      this.$emit('register-user', this.registerForm);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/core/variables';

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 90%;

  margin: auto;
  padding: 2rem 0;

  color: var(--v-primaryFont-base);
}

.form-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &__item-left {
    flex: 1;
    margin-right: 0.5rem;
  }

  &__item-right {
    flex: 1;
    margin-left: 0.5rem;
  }
}

.legend {
  font-size: $small-font-size;
  color: #f44336;
  margin-top: rem(5px);
}

.required::after {
  content: '*';
  color: #f44336;
}

@include breakpoint(small) {
  .form {
    padding: 5rem 0;
  }
}
</style>
