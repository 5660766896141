<template>
  <div class="app-exception">
    <div class="app-exception__error">
      <div class="error__title">{{ $t('errorPage.title') }}</div>
      <div class="error__code">{{ $t(`errorPage.${code}.code`) }}</div>
      <div class="error__content">{{ $t(`errorPage.${code}.content`) }}</div>
      <p class="error__description">
        {{ $t(`errorPage.${code}.description`) }}
      </p>
    </div>

    <router-link to="/" tag="button" class="app-exception__action">
      {{ $t('errorPage.action') }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'AppException',
  props: {
    code: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import './src/styles/core/variables';

.app-exception {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.app-exception {
  &__error {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-bottom: 5%;
  }

  &__action {
    border-radius: 5px;
    background-color: var(--v-primary-base);
    padding: 5px 10px;

    font-size: $default-font-size;
    font-weight: 600;
    color: white;
  }
}

.error {
  &__title {
    opacity: 0.4;

    font-size: 56px;
    font-weight: 900;
    line-height: 140%;
  }

  &__code {
    opacity: 0.2;

    font-weight: 900;
    font-size: 36px;
    line-height: 100%;

    margin-bottom: 1rem;
  }

  &__content {
    font-size: 24px;
    font-weight: 900;
  }

  &__description {
    max-width: 600px;
    text-align: center;

    font-size: 16px;
  }
}
</style>
