<template>
  <div id="update-password">
    <div class="form" v-if="isValidated" @submit.prevent @submit="update()">
      <v-form v-model="valid">
        <h1>{{ $t('auth.update.title') }}</h1>

        <v-text-field
          color="primary"
          type="password"
          v-model="updateForm.password"
          :rules="passwordRules"
          :label="$t('auth.update.password')"
          required
        />

        <v-text-field
          color="primary"
          type="password"
          v-model="updateForm.passwordAgain"
          :label="$t('auth.update.passwordAgain')"
          required
        />

        <div class="controls">
          <v-btn
            class="controls__primary"
            color="primary"
            :disabled="!valid || !samePassword || isSending"
            :loading="isSending"
            outlined
            type="submit"
            >{{ $t('auth.update.submit') }}</v-btn
          >

          <v-btn color="primary" text to="Login">{{ $t('auth.update.login') }}</v-btn>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  VALIDATE_TOKEN,
  UPDATE,
  APP_PASSWORD_MODULE,
} from '@/stores/umanize-app/actions/password/password.actions';
import validatorsHelper from '@/helpers/forms/validators.helper';

export default {
  name: 'UpdatePassword',
  data: () => ({
    updateForm: {
      password: '',
      passwordAgain: '',
    },
    passwordRules: validatorsHelper.PASSWORD,
    valid: false,
  }),
  computed: {
    ...mapGetters(APP_PASSWORD_MODULE, ['error', 'isSending', 'isValidated']),
    samePassword() {
      return this.updateForm.password === this.updateForm.passwordAgain;
    },
  },
  methods: {
    ...mapActions(APP_PASSWORD_MODULE, [VALIDATE_TOKEN, UPDATE]),
    async update() {
      const updated = await this[UPDATE]({
        passwordForm: this.updateForm,
        token: this.$route.query.token,
      });

      if (updated) {
        this.$router.push({ name: 'Login' });
      }
    },
    async checkToken() {
      try {
        await this[VALIDATE_TOKEN](this.$route.query.token);
      } catch (e) {
        this.$router.push({ name: 'ResetPassword' });
      }
    },
  },
  mounted() {
    this.checkToken();
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/core/variables';

#update-password {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: auto;
}

.form {
  padding: 5rem 0;
  width: 80vw;
}

.controls {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__primary {
    margin: 1rem;
  }
}

@include breakpoint(small) {
  .form {
    width: 30rem;
  }
}
</style>
